<template>
  <main class="flex items-center justify-center h-screen px-4 bg-gray-100">
    <div
      class="flex flex-col items-center justify-center sm:flex-row sm:items-center"
    >
      <div class="sm:ml-4 text-center sm:text-left">
        <h1 class="flex justify-center sm:justify-start items-center">
          <span class="text-xl font-medium text-gray-600 sm:text-2xl">
            {{ t('Something happend') }}
          </span>
        </h1>
        <p class="text-base font-normal text-gray-600">
          {{ t('Sorry for inconvenient. Please retry later') }}
        </p>
        <p class="text-base font-normal text-gray-600">
          {{ t('Go back to') }}

          <router-link class="text-blue-500 capitalize" to="/">{{
            t('home')
          }}</router-link>
        </p>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
</script>
